import {
  AssetClass,
  CborData,
  ConstrData,
  HInt,
  HMap,
  IntData,
  MapData,
  PubKeyHash,
  Time,
  UplcData,
  UplcProgram,
} from '@hyperionbt/helios';
import {
  NetworkParams,
  TxOutput,
  TxWitnesses,
  Value,
  Tx,
  UTxO,
  Address,
  Program,
  Datum,
  MintingPolicyHash,
  hexToBytes,
  Assets,
  bytesToHex,
} from '@hyperionbt/helios';
import constants from '../constants';
import getAbsoluteURL from '../getAbsoluteUrl';

import { CardanoWalletExtended } from '../wallet/WalletContext';
import {
  CoinSelectionErrorCode,
  DropspotMarketError,
  APIError,
} from './DropspotMarketError';
import { Asset } from '../../types';

import firebase from 'firebase/app';
import 'firebase/auth';
import { Cardano } from '../../types/cardano';
import { coinSelection } from './CoinSelection';
import { logger } from '../Logger';

export type BUILD_ACTION =
  | 'Building'
  | 'Finalizing'
  | 'Signing'
  | 'Submitting'
  | 'Submitted';

function percentageToContractPCT(percent: number) {
  return Math.floor(10 / percent);
}

export async function getUtxos(
  wallet: CardanoWalletExtended,
  lovelace: BigInt,
  multiAsset?: { mph: MintingPolicyHash; name: number[]; quantity: number }
) {
  logger.debug({
    message: 'In getUtxos',
    level: 'Info',
    timestamp: Date.now(),
    additional: {
      lovelace: lovelace.toString(),
      multiAsset: multiAsset
        ? {
            policy: multiAsset.mph.hex,
            name: bytesToHex(multiAsset.name),
            quantity: multiAsset.quantity,
          }
        : undefined,
    },
  });
  const start = performance.now();
  let utxos: string[] | undefined = [];
  if (wallet.name === 'Flint Wallet') {
    utxos = await wallet.getUtxos();
  } else {
    let page = -1;

    try {
      while (true) {
        page++;

        const walletUtxos = await wallet.getUtxos(undefined, {
          page,
          limit: 100,
        });

        console.log('walletUtxos', page, walletUtxos);
        if (
          !walletUtxos ||
          (utxos[utxos.length - 1] !== undefined &&
            utxos[utxos.length - 1] === walletUtxos[walletUtxos.length - 1])
        ) {
          break;
        }

        utxos.push(...walletUtxos);
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (!utxos) {
    throw new DropspotMarketError({
      type: 'COIN_SELECTION',
      code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
      info: 'Not enough Coin to pay for transaction',
    });
  }

  const allUtxos = utxos.map((utxo) => UTxO.fromCbor(hexToBytes(utxo)) as UTxO);

  let asset: Assets | undefined = undefined;
  if (multiAsset) {
    asset = new Assets();
    asset.addComponent(
      multiAsset.mph,
      multiAsset.name,
      BigInt(multiAsset.quantity)
    );
  }

  const end = performance.now();
  logger.debug({
    message: 'getUTxOs completed',
    level: 'Info',
    timestamp: Date.now(),
    additional: {
      start: start,
      end,
      took: end - start,
      utxos: allUtxos.length,
    },
  });

  const networkParams = await getNetworkParams();
  return coinSelection(
    allUtxos,
    new Value(lovelace.valueOf(), asset),
    networkParams
  );
}

export async function addInputsFromWallet(
  amountFromWalletADA: BigInt,
  tx: Tx,
  wallet: CardanoWalletExtended,
  multiAsset?: { mph: MintingPolicyHash; name: number[]; quantity: number }
) {
  console.log('In addInputsFromWallet 2', amountFromWalletADA);

  // Get every UTxO from the Wallet, for flint thats just a call to wallet.getUtxos
  // for other Wallets we will need to page thru

  const [selectedUtxos, otherUtxos] = await getUtxos(
    wallet,
    amountFromWalletADA,
    multiAsset
  );
  console.log('coinSelection', selectedUtxos);
  tx.addInputs(selectedUtxos);

  return { selectedUtxos, otherUtxos };
}

export async function signTransaction(tx: Tx, wallet: Cardano) {
  try {
    const signed = await wallet.signTx(tx.toCborHex(), true);
    console.log('createListing', 'Signed Txn', signed);

    console.log('createListing', 'Add Signatures');

    const witnesses = TxWitnesses.fromCbor(hexToBytes(signed));
    return witnesses.signatures;
  } catch (err) {
    console.error('Signing Error', err);
    console.error('Signing Error', JSON.stringify(tx.dump()));
    throw new DropspotMarketError({
      code: (err as APIError).code,
      info: (err as APIError).info,
      type: 'SIGN',
    });
  }
}

export async function submitTx(tx: string, assetIds: string[]) {
  console.log('Submit Tx', tx);
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) throw new Error('No user');

  try {
    const response = await fetch(
      getAbsoluteURL(`/api/blockfrost/plutus/utils/txs/submit`),
      {
        method: 'POST',
        body: JSON.stringify({ tx, assetIds }),
        headers: {
          'Content-Type': 'application/cbor',
          Authorization: `Bearer ${await currentUser.getIdToken(true)}`,
        },
      }
    );

    if (!response.ok) {
      console.error(await response.json());
      throw new Error('Submit error');
    }

    return (await response.json()) as string;

    // const txHash = await this.wallet.submitTx(toHex(signedTx.to_bytes()));
    // return txHash;
  } catch (err) {
    console.error('Submit error');
    console.error(err);
    throw new DropspotMarketError({
      code: 2,
      info: (err as Error).message,
      type: 'SEND',
    });
  }
}

export function balanceAssets(
  walletAddress: Address,
  addedWalletUTxOs: UTxO[],
  tx: Tx,
  networkParams: NetworkParams,
  ignoreAssets?: Assets // These are assets that we do not want to create a 'change' output for.
) {
  let balanceAssets = addedWalletUTxOs
    .map((wUtxo) => wUtxo.value.assets)
    .reduce((allAssets, assets) => allAssets.add(assets), new Assets());

  console.log(bytesToHex(balanceAssets.toCbor()));
  if (ignoreAssets) {
    console.log('ignoreAssets', bytesToHex(ignoreAssets.toCbor()));
    balanceAssets = balanceAssets.applyBinOp(ignoreAssets, (a, b) => a - b);
  }

  console.log(bytesToHex(balanceAssets.toCbor()), balanceAssets.isZero());
  if (!balanceAssets.isZero()) {
    // Create a TXout to the Change Address with these Unbalanced Assets
    tx.addOutput(
      createSafeTxOutput(
        networkParams,
        walletAddress,
        new Value(BigInt(0), balanceAssets)
      )
    );
  }

  return tx;
}

export function createSafeTxOutput(
  networkParams: NetworkParams,
  address: Address,
  value: Value,
  datum?: Datum,
  refScript?: UplcProgram
) {
  const txOutput = new TxOutput(address, value, datum, refScript);
  txOutput.correctLovelace(networkParams);

  if (txOutput.value.lovelace < BigInt(1_000_000)) {
    txOutput.value.setLovelace(BigInt(1_000_000));
  }

  return txOutput;
}

export type DSDatum =
  | {
      type: 'Sale';
      datum: DSDatumSale;
    }
  | {
      type: 'Sale-Mint';
      datum: DSDatumMint;
    };

type DSDatumSale = {
  ownerAddress: string;
  amount: number;
  royalties: { percent: number; address: string }[];
  disbursements: { percent: number; address: string }[];
  policy: string;
  tokenName: string;
  assetStandard?: 'CIP68' | 'CIP25';
  startDatePOSIX: string;
  version: '12' | '13';
};

type DSDatumMint = DSDatumSale & {
  dropspotRate: number;
  cancelFee: number;
};

type X = { map: X[] } | { bytes: string } | { int: number } | DSInlineDatum;

type DSInlineDatum = {
  fields: X[];
  constructor: number;
};

/**
 * To turn a %age into something that we can use OnChain we need to do the Formula:
 * x = 10 / y
 * Where
 *  x is the Integer value for OnChain and
 *  y is the %age
 * @param frac
 * @returns
 */
export function fractionToScriptPct(frac: number) {
  return Math.round(10 / frac);
}

export async function datum(myDatum: DSDatum) {
  return myDatum.datum.version === '12' ? v12Datum(myDatum) : v13Datum(myDatum);
}

async function v13Datum(myDatum: DSDatum) {
  console.log('In v13Datum', myDatum);
  const {
    contracts: { dsMarketContract, superCubeModule },
  } = await import('@dropspot-io/contract-api');

  const program = Program.new(dsMarketContract, [superCubeModule]);

  const { Datum: DSDatum } = program.types;

  const r = new (HMap(PubKeyHash, HInt))(
    (myDatum.datum.royalties || []).map(({ address, percent }) => {
      return [
        Address.fromBech32(address).pubKeyHash,
        new HInt(percentageToContractPCT(percent)),
      ] as const;
    })
  );

  const d = new (HMap(PubKeyHash, HInt))(
    (myDatum.datum.disbursements || []).map(({ address, percent }) => {
      return [
        Address.fromBech32(address).pubKeyHash,
        new HInt(percentageToContractPCT(percent)),
      ] as const;
    })
  );

  const dsDatum = new DSDatum(
    r,
    d,
    Address.fromBech32(myDatum.datum.ownerAddress).pubKeyHash,
    new HInt(myDatum.datum.amount),
    new AssetClass([
      MintingPolicyHash.fromHex(myDatum.datum.policy),
      hexToBytes(myDatum.datum.tokenName),
    ]),
    new Time(BigInt(myDatum.datum.startDatePOSIX))
  );

  const datum = Datum.inline(dsDatum);

  return {
    hash: datum.hash.hex,
    datum: bytesToHex(datum.toCbor()),
    pure: dsDatum._toUplcData(),
  };
}

function v12Datum(myDatum: DSDatum) {
  console.log('In v12Datum', myDatum);

  const {
    datum: {
      ownerAddress,
      amount,
      royalties,
      disbursements,
      policy,
      tokenName,
      startDatePOSIX,
    },
  } = myDatum;

  // 1. Royalties

  const r = new MapData(
    (royalties || []).map(({ address, percent }) => {
      return [
        UplcData.fromCbor(Address.fromBech32(address).pubKeyHash.toCbor()),
        IntData.fromCbor(
          CborData.encodeInteger(BigInt(percentageToContractPCT(percent)))
        ),
      ] as [UplcData, UplcData];
    })
  );

  const d = new MapData(
    (disbursements || []).map(({ address, percent }) => {
      return [
        UplcData.fromCbor(Address.fromBech32(address).pubKeyHash.toCbor()),
        IntData.fromCbor(
          CborData.encodeInteger(BigInt(percentageToContractPCT(percent)))
        ),
      ] as [UplcData, UplcData];
    })
  );

  const owner = UplcData.fromCbor(
    Address.fromBech32(ownerAddress).pubKeyHash.toCbor()
  );

  const price = UplcData.fromCbor(CborData.encodeInteger(BigInt(amount)));

  const token = new ConstrData(0, [
    UplcData.fromCbor(CborData.encodeBytes(hexToBytes(policy))),
    UplcData.fromCbor(
      CborData.encodeBytes(
        hexToBytes(Buffer.from(tokenName, 'utf8').toString('hex'))
      )
    ),
  ]);

  const startDate = UplcData.fromCbor(
    CborData.encodeInteger(BigInt(startDatePOSIX))
  );

  const fields: UplcData[] = [r, d, owner, price, token, startDate];

  if (myDatum.type === 'Sale-Mint') {
    fields.push(
      IntData.fromCbor(
        CborData.encodeInteger(
          // dropspotRate is a 'real' number like 2.5% etc
          BigInt(Math.ceil(1000 / myDatum.datum.dropspotRate))
        )
      )
    );

    fields.push(
      IntData.fromCbor(CborData.encodeInteger(BigInt(myDatum.datum.cancelFee)))
    );
  }

  const whole = new ConstrData(0, fields);

  const datum = Datum.hashed(whole);

  return {
    hash: datum.hash.hex,
    datum: Buffer.from(whole.toCbor()).toString('hex'),
    pure: whole,
  };
}

export async function getNetworkParams() {
  return new NetworkParams(
    await fetch(constants.NETWORK_PARAMS_URL).then((r) => r.json())
  );
}

/**
 * Get a Payment address, in Hex for the provided Wallet
 * @param wal The users Cardano Wallet
 * @returns
 */
export async function getAddress(wal: Cardano) {
  let addrs = await wal.getUsedAddresses();

  if (addrs.length > 0) {
    return addrs[0];
  }

  addrs = await wal.getUnusedAddresses();

  if (addrs.length > 0) {
    return addrs[0];
  }

  throw new Error('No Wallet Payment Addresses');
}

export async function updateAssetListing(
  assetId: string,
  price: number | null,
  txHash: string | null,
  marketStatus: 'LISTED' | 'UNLISTED' | 'OFFER',
  action: 'BUY' | 'LIST' | 'CANCEL' | 'RELIST',
  tradeOwner?: string,
  asset?: Asset
) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) return;

  const url = getAbsoluteURL('/api/updateAssetListing');

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await currentUser.getIdToken()}`,
    },
    body: JSON.stringify({
      assetId,
      price,
      txHash,
      marketStatus,
      action,
      tradeOwner,
      ...(asset || {}),
    }),
  });

  if (!result.ok) {
    console.error(await result.json());
  }
}

export async function getWalletPKH(wal: CardanoWalletExtended) {
  let addrs = await wal.getUsedAddresses();

  if (addrs.length > 0) {
    return Address.fromHex(addrs[0]).pubKeyHash;
  }

  addrs = await wal.getUnusedAddresses();

  if (addrs.length > 0) {
    return Address.fromHex(addrs[0]).pubKeyHash;
  }

  throw new Error('No Wallet Payment Addresses');
}
